<template>
  <div id="details">
    <div class="w">
      <div class="details">
        <h1>
          {{ Arr.title }}
        </h1>
        <h2 style="margin-top: 12px;margin-bottom: 12px;">
          {{ Arr.date }}
        </h2>
        <div class="details_test">
         {{ Arr.content }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      title: '工业智能化背景下制造业大数据的应用',
      Arr:{}
    };
  },
  methods: {},

  created() { 
    console.log(localStorage.getItem('data'));
    this.Arr=JSON.parse(localStorage.getItem('data'))
    console.log(this.Arr);

  },

  mounted() { },

  computed: {},

  watch: {},
};
</script>
<style lang="scss">
//页面内容
@import url('../assets/css/details.css');
</style>